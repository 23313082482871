<template>
  <div class="template-flight-list">
    <div class="col-12 pt-4">
      <h2>Flights</h2>
      <div class="card">
        <div class="card-body">
          <div class="row mb-sm-2">
            <div class="col-sm-4 mb-3">
              <b-form-datepicker
                id="startDate"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                placeholder="Choose start date"
                local="en"
                v-model="startDate"
              ></b-form-datepicker>
            </div>
            <div class="col-sm-4 mb-3">
              <b-form-datepicker
                id="endDate"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                placeholder="Choose end date"
                local="en"
                v-model="endDate"
              ></b-form-datepicker>
            </div>
            <div class="row ml-1">
              <div class="col-3">
                <b-button
                  variant="light"
                  size="sm"
                  @click="getFlightList()"
                  :disabled="isDisable"
                  ><i class="uil uil-check font-18 text-success"></i
                ></b-button>
              </div>
              <div class="col-9 justify-content-end" v-if="rows > 0">
                <b-button
                  variant="light"
                  size="sm"
                  @click="csvExport"
                  :disabled="selectedFlight.length == 0"
                  ><i class="font-18 text-success">Export to CSV</i></b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Filters card -->
      <div class="card">
        <div class="card-body">
          <h6>Filters</h6>
          <div v-if="!this.$apollo.queries.flightList.loading">
            <div class="row">
              <div
                v-if="['webadmin'].includes(this.currentRole)"
                class="col-12 col-sm-4 mb-3"
              >
                <multiselect
                  v-model="selectedZone"
                  :searchable="false"
                  :multiple="true"
                  :options="zone"
                  placeholder="Filter By Zones"
                />
              </div>
              <!----operators filter  -->
              <div class="col-12 col-sm-4 mb-3">
                <multiselect
                  v-model="selectedOperator"
                  :searchable="false"
                  :multiple="true"
                  :options="operators"
                  placeholder="Filter By Operators"
                />
              </div>
              <!-- Orders multi select-->
              <div class="col-12 col-sm-4">
                <multiselect
                  v-model="selectedOrderIDs"
                  :close-on-select="false"
                  :multiple="true"
                  :options="orderIDs"
                  placeholder="Filter by OrderID"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mt-5">
            <div class="col-5">
              <h6>Total Flights : {{ flightTable.length }}</h6>
            </div>
            <div class="col-7 mb-3 d-flex justify-content-end">
              <b-button
                variant="light"
                size="sm"
                @click="selectAllflight"
                :disabled="isSelectAllBtn"
              >
                {{ ordersSelectBtn }}
              </b-button>
            </div>
          </div>
          <!-- Table -->
          <div
            class="d-flex justify-content-center"
            v-if="this.$apollo.queries.flightList.loading"
          >
            Loading.......
          </div>
          <div v-else class="table-responsive mb-0">
            <b-table
              show-empty
              striped
              :items="flightTable"
              ref="table"
              :fields="fields"
              :no-border-collapse="true"
              :no-provider-sorting="true"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              @row-clicked="onRowSelected"
              :tbody-tr-class="selectedRowStyle"
            >
              <template v-slot:cell(selected)="{ item, field: { key } }">
                <b-checkbox
                  v-model="item[key]"
                  @change="checkselected(item)"
                  size="lg"
                />
              </template>
              <template v-slot:cell(wc_order_id)="row">
                {{ row.item.wc_order_id }}
              </template>
              <template v-slot:cell(flight_time)="row">
                {{ formatDateTime(row.item.flight_time) }}
                <br />
                <span> {{ formatTime(row.item.flight_time) }} </span>
              </template>

              <template v-slot:cell(passenger)="row">
                {{ row.item.passenger.firstname }}
                {{ row.item.passenger.lastname }}
                <br />
                {{ row.item.passenger.gender }} |
                {{ calculateAge(row.item.passenger.dob) }}
              </template>

              <template v-slot:cell(contact)="row">
                {{ row.item.pilot.user.phone_no }}
                <br />
                {{ row.item.wc_order.order_data.pickupLocation }}
              </template>

              <template v-slot:cell(flight_type)="row">
                {{ row.item.flight_type }}
                <br />
                {{ row.item.pilot.operator.zone.name }}
              </template>

              <template v-slot:cell(pilot)="row">
                {{ row.item.pilot.user.name }}
                <br />
                {{ row.item.pilot.operator.company_name }}
              </template>

              <template v-slot:cell(status)="row">
                {{ row.item.wc_order.order_data.bookingStatus }}
                <br />
                ₹{{ row.item.wc_order.order_data.totalPayment }} receivable
              </template>

              <template v-slot:cell(actions)>
                <a title="View" class="action-icon view">
                  <i class="mdi mdi-eye"></i>
                </a>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="row">
            <!-- # of entries per page selector -->
            <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="ml-2 mr-2"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="dataTables_paginate paging_simple_numbers">
                <b-pagination
                  class="justify-content-center justify-content-sm-end"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="selectedPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

<script>
import { GET_FLIGHT_LIST } from "@/graphql/queries/queries";
import { DateTime } from "luxon";
import _ from "lodash";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "flightListing",
  components: {
    Multiselect,
  },
  data() {
    return {
      flightList: {},
      flightTable: [],
      zone: [],
      operators: [],
      orderIDs: [],
      selectedOrderIDs: [],
      selectedZone: [],
      selectedOperator: [],
      selectedFlight: [],
      isSelectAllflights: false,
      isSelectAllBtn: true,
      flightCSV: [],
      offsetValue: 0,
      startDate: null,
      endDate: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "flight_id",
          label: "Flight",
          stickyColumn: true,
          class: "pilotID",
          sortable: true,
        },
        {
          key: "wc_order_id",
          label: "Order",
          class: "pilotID",
          sortable: true,
        },
        {
          key: "flight_time",
          label: "Date/Time",
          tdClass: "dateTime",
          sortable: true,
        },
        { key: "passenger", label: "Passenger" },
        { key: "contact", label: "Contact" },
        { key: "flight_type", label: "Type" },
        { key: "pilot", label: "Pilot" },
        { key: "status", label: "Status" },
        { key: "actions", label: "actions" },
      ],
    };
  },
  computed: {
    ...mapState(["currentRole"]),

    ordersSelectBtn() {
      return this.isSelectAllflights ? "Select none" : "Select All";
    },
    isDisable() {
      return this.startDate === null || this.endDate === null ? true : false;
    },
    rows() {
      return this.flightTable.length > 0 ? this.flightTable.length : 0;
      // if (Object.keys(this.flightList).length > 0) {
      //   return this.flightList.flights_aggregate.aggregate.count;
      // }
      // return 0;
    },
  },
  watch: {
    selectedOrderIDs() {
      this.currentPage = 1;
      this.filerByOrderID();
    },
    flightList() {
      this.zone = [];
      this.operators = [];
      this.orderIDs = [];
      this.flightTable = this.getFlights();

      //zone
      this.flightList.flights.forEach((f) => {
        if (!this.zone.includes(f.pilot.operator.zone.name)) {
          this.zone.push(f.pilot.operator.zone.name);
        }
        if (!this.orderIDs.includes(f.wc_order_id)) {
          this.orderIDs.push(f.wc_order_id);
        }
      });
      this.operatorsList();
      //operator
    },
    //filter as per selected zone
    selectedZone() {
      this.isSelectAllflights = false;
      this.selectedFlight = [];
      this.operators = [];
      this.currentPage = 1;
      this.selectedOperator = [];
      _.forEach(this.flightTable, (flight) => {
        if (flight.selected && !this.isSelectAllflights) {
          flight.selected = false;
        }
      });
      this.flightTable = this.getFlights();
      this.getOrderIdList();
      this.operatorsList();
      this.filerFlightByZone();
    },
    selectedOperator() {
      this.getOrderIdList();
      this.filterFlightByOperator();
    },

    perPage() {
      //set the offset value to default whenever show entries get changes (i.e when limit gets changed)
      this.offsetValue = 0;
    },
  },
  methods: {
    getOrderIdList() {
      this.selectedOrderIDs = [];
      this.orderIDs = [];
      this.getFlights().forEach((f) => {
        if (
          !this.orderIDs.includes(f.wc_order_id) &&
          this.selectedZone.includes(f.pilot.operator.zone.name) &&
          this.selectedOperator.includes(f.pilot.operator.company_name)
        ) {
          this.orderIDs.push(f.wc_order_id);
        } else if (
          !this.orderIDs.includes(f.wc_order_id) &&
          this.selectedZone.includes(f.pilot.operator.zone.name) &&
          this.selectedOperator.length == 0
        ) {
          this.orderIDs.push(f.wc_order_id);
        } else if (
          !this.orderIDs.includes(f.wc_order_id) &&
          this.selectedZone.length == 0 &&
          this.selectedOperator.includes(f.pilot.operator.company_name)
        ) {
          this.orderIDs.push(f.wc_order_id);
        } else if (
          !this.orderIDs.includes(f.wc_order_id) &&
          this.selectedZone.length == 0 &&
          this.selectedOperator.length == 0
        ) {
          this.orderIDs.push(f.wc_order_id);
        }
      });
    },
    operatorsList() {
      this.flightTable.forEach((f) => {
        if (
          !this.operators.includes(f.pilot.operator.company_name) &&
          this.selectedZone.includes(f.pilot.operator.zone.name)
        ) {
          this.operators.push(f.pilot.operator.company_name);
        } else if (
          this.selectedZone.length == 0 &&
          !this.operators.includes(f.pilot.operator.company_name)
        ) {
          this.operators.push(f.pilot.operator.company_name);
        }
      });
    },
    filerFlightByZone() {
      if (this.selectedZone) {
        this.flightTable = this.flightTable.filter((f) =>
          this.selectedZone.includes(f.pilot.operator.zone.name)
        );
      } else {
        this.flightTable = this.getFlights();
      }
    },
    filterFlightByOperator() {
      if (this.selectedOperator) {
        this.flightTable = this.getFlights().filter((f) =>
          this.selectedOperator.includes(f.pilot.operator.company_name)
        );
      }
    },
    filerByOrderID() {
      if (this.selectedOrderIDs.length > 0) {
        this.flightTable = this.getFlights().filter((f) =>
          _.includes(this.selectedOrderIDs, f.wc_order_id)
        );
      } else {
        this.flightTable = this.getFlights().filter((f) =>
          _.includes(this.orderIDs, f.wc_order_id)
        );
      }
    },
    formatDateTime(date) {
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
    calculateAge(dob) {
      let d1 = DateTime.local();
      let d2 = DateTime.fromISO(dob);
      let age = d1.diff(d2, ["year", "months", "days"]).toObject();
      return age.years;
    },
    getFlightList() {
      this.selectedZone = [];
      this.isSelectAllBtn = false;
      this.isSelectAllflights = false;
      this.selectedFlight = [];
      this.$apollo.queries.flightList.skip = false;
      this.$apollo.queries.flightList.refetch();
      this.flightTable = this.getFlights();
      // set "selected" property value to false before selecting row
      _.forEach(this.flightTable, (flight) => {
        this.$set(flight, "selected", false);
      });
    },
    selectedPage() {
      this.offsetValue = (this.currentPage - 1) * this.perPage;
    },
    getFlights() {
      return this.flightList.flights || [];
    },
    checkselected(item) {
      if (item.selected && !_.includes(this.selectedFlight, item.flight_id)) {
        //console.log("item: ", item.flight_id);
        this.selectedFlight.push(item);
      } else {
        this.selectedFlight.splice(this.selectedFlight.indexOf(item), 1);
      }
    },
    onRowSelected(item) {
      if (item.selected) {
        item.selected = false;
        this.selectedFlight.splice(this.selectedFlight.indexOf(item), 1);
      } else {
        this.$set(item, "selected", true);
        this.selectedFlight.push(item);
      }
    },
    selectAllflight() {
      this.selectedFlight = [];
      this.isSelectAllflights = !this.isSelectAllflights;
      this.flightTable.forEach((flight) => {
        if (!this.isSelectAllflights) {
          this.$set(flight, "selected", false);
        } else {
          this.$set(flight, "selected", true);
          this.selectedFlight.push(flight);
        }
      });
    },

    selectedRowStyle(item, type) {
      if (!item || type !== "row") return;
      if (item.selected) {
        return ["b-table-row-selected", "table-primary", "cursor-pointer"];
      } else {
        return ["cursor-pointer"];
      }
    },
    csvExport() {
      // const dt = DateTime.local();
      let csvContent = "data:text/csv;charset=utf-8,";
      const csvHeaders = [
        "Traveller ID",
        "Cover Price Id",
        "Gender",
        "First Name",
        "Last Name",
        "Date of Birth",
        "Email",
        "Phone number",
        "Name of Tour Operator",
        "Operator Mobile Number",
        "Nominee Name",
        "Relation with Nominee",
        "Nationality",
        "ID Number (Passport)",
        "Address",
        "City",
        "Pin Code",
        "Travelling From",
        "Start Date",
        "End Date",
        "Emergency Name",
        "Emergency Contact",
        "Emergency Email",
        "Travelling To",

        // "Registration Date",
        // "Policy Start Date",
        // "Title",
        // "First Name",
        // "Last Name",
        // "Date of Birth",
        // "Mobile Number",
        // "Email",

        // fields not required
        // "Address 1",
        // "Address 2",
        // "Nationality",
        // "Nominee Name",
        // "Relationship with Nominee",
        // "ID Proof",
        // "Pre Existing Disease",
        // "Disease Name",
        // "I.D.Proof number",

        // static data
        // "Product Name",
        // "Price",
        // "Variation",
      ];
      const csvData = _.map(this.selectedFlight, (o) => {
        const pax = [];
        // pax.push(dt.toISODate());
        // pax.push(
        //   DateTime.fromISO(o.wc_order.order_data.startTime).toUTC().toISODate()
        // );
        // const title =
        //   o.passenger.gender === "Male"
        //     ? "Mr"
        //     : o.passenger.gender === "Female"
        //     ? "Ms"
        //     : "Others";

        pax.push("na");
        pax.push("ASC448");
        pax.push(o.passenger.gender);
        pax.push(o.passenger.firstname);
        pax.push(o.passenger.lastname);
        pax.push(o.passenger.dob);
        pax.push(o.wc_order.order_data.customerEmail);
        pax.push(o.passenger.phone);
        pax.push("ParaBooking");
        pax.push("8091149832");
        pax.push("none");
        pax.push("none");
        pax.push("Indian");
        pax.push("none");
        pax.push("none");
        pax.push("none");
        pax.push("none");
        pax.push("India");
        pax.push(
          DateTime.fromISO(o.wc_order.order_data.startTime).toUTC().toISODate()
        );
        pax.push(
          DateTime.fromISO(o.wc_order.order_data.startTime).toUTC().toISODate()
        );
        pax.push("none");
        pax.push("none");
        pax.push("none");
        pax.push("India");

        // pax.push(title);
        // pax.push(o.passenger.firstname);
        // pax.push(o.passenger.lastname);
        // pax.push(o.passenger.dob);
        // pax.push(o.passenger.phone);
        // pax.push(o.passenger.email);
        // fields not required
        // for (let i = 0; i < 9; i++) {
        //   pax.push("");
        // }
        // static fields
        // pax.push("Paragliding");
        // pax.push("150");
        // pax.push("basic");
        return pax;
      });
      csvContent += csvHeaders.join(",") + "\n";
      csvContent += _.join(
        _.map(csvData, (d) => {
          return _.join(d, ",");
        }),
        "\n"
      );
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      const fileName = `pb-paxdata-${DateTime.local().toISO()}.csv`;
      link.setAttribute("download", fileName);
      link.click();
    },
  },
  apollo: {
    flightList: {
      // graphql query
      query: GET_FLIGHT_LIST,
      fetchPolicy: "no-cache",
      variables() {
        return {
          from: this.startDate,
          to: DateTime.fromISO(this.endDate)
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATE_FULL),
        };
      },
      update(data) {
        this.$apollo.queries.flightList.skip = true;
        return data;
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
      skip: true,
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "@/css/sass_files";

.template-flight-list {
  padding-top: 0;
  margin: 0;
  h2 {
    text-transform: uppercase;
    font-size: 20px;
  }

  table.b-table {
    color: #6c757d;

    td {
      background: none !important;
    }

    td.pilot-name {
      font-weight: 600;
    }

    td.operator span,
    td.pilot-name span {
      display: block;
      font-size: 13px;
      color: #98a6ad;
      font-weight: 400;
    }

    .action-icon {
      display: inherit;
      padding: 0 5px;
      font-size: 1.2rem;
      color: #98a6ad;
      cursor: pointer;
    }

    .action-icon:hover {
      color: #6c757d;
    }

    .action-icon.edit {
      margin-left: 10px;
    }
  }

  h4.header-title {
    display: inline-block;
    vertical-align: sub;
    margin-right: 30px;
  }
}

::v-deep {
  tr.b-table-row-selected {
    td.b-table-sticky-column {
      background: #b8daff !important;
    }
  }
}
</style>
